// ImageModal.js

import React, { useState } from 'react';

const ImageModal = ({ imageUrl }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <div className='flex justify-center items-center mt-[1rem]'>
                <img
                    src={imageUrl}
                    alt="post image"
                    width={200}
                    height={250}
                    onClick={openModal}
                    className="cursor-pointer"
                />
            </div>

            {modalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="relative max-w-screen-md mx-auto p-4">
                        <span
                            className="absolute top-4 right-6 text-5xl text-red-500 cursor-pointer"
                            onClick={closeModal}
                        >
                            ×
                        </span>
                        <img
                            src={imageUrl}
                            alt="Full-size image"
                            className="max-w-full max-h-[80vh]"
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default ImageModal;
