import React, { useState, useEffect } from 'react';
import { CustomPostButton } from './CustomPostButton';
import { FaHeart, FaComment } from 'react-icons/fa';
import icon from '../assets/icon.jpg';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from './Header';
import { BsCheckCircle } from 'react-icons/bs';
import ImageModal from './ImageModal';
import { Footer } from './Footer';
import { BASE_URL } from './BaseUrl';
import user from "../assets/user.png";

export const Post = () => {
    const [likedPosts, setLikedPosts] = useState([]);
    const [joined, setJoined] = useState(0);
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [commentPostId, setCommentPostId] = useState(null);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    const [commentsForPost, setCommentsForPost] = useState([]);
    const [userName, setUserName] = useState("");
    const [userData, setUserData] = useState({});
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [showScrollButton, setShowScrollButton] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 100) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    function HandleClose() {
        setShowCommentModal(false);
    }

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');


    const fetchPosts = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/get-posts`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.success) {
                // Sort posts by createdAt date in descending order
                const sortedPosts = response.data.message.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                setPosts(sortedPosts);
                setLoading(false);


                const likedPostsData = sortedPosts.filter(post => post.likes.includes(userId));
                setLikedPosts(likedPostsData.map(post => post._id));
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert('Unauthorized access. Please log in again.');
            }
        }
    };


    useEffect(() => {
        fetchPosts();
    }, []);

    const toggleLike = async (postId) => {

        const isLiked = likedPosts.includes(postId);

        try {
            const response = await axios.post(
                `${BASE_URL}/${isLiked ? 'unlike-post' : 'like-post'}`,
                { postId: postId, userId: userId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data.success) {
                if (isLiked) {
                    setLikedPosts(likedPosts.filter(id => id !== postId));
                } else {
                    setLikedPosts([...likedPosts, postId]);
                }
                fetchPosts();
            } else {

                console.error('Server response indicates unsuccessful operation:', response.data);
            }
        } catch (error) {
            console.error('Error toggling like:', error);
        }
    };


    const toggleCommentModal = (postId) => {
        setCommentPostId(postId);
        setShowCommentModal(prevState => !prevState);
    };

    const handleSaveComment = async (postId) => {
        if (newComment.trim() !== '') {
            try {
                const apiRequest = await axios.post(
                    `${BASE_URL}/comment-post`,
                    {
                        comment: newComment,
                        postId: postId,
                        userId: userId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (apiRequest.data.success) {
                    fetchPosts();
                    toggleCommentModal(postId);
                } else {
                    console.log("Failed to add comment");
                }
            } catch (error) {
                console.error('Error adding comment:', error);
            }
            setNewComment('');
        }
    };

    async function getUserInfoForComment(userId) {
        try {
            if (!userId) throw new Error("User ID is required.");

            const response = await axios.post(`${BASE_URL}/user-info`, { userId });

            if (!response.data.success) {
                throw new Error("Failed to get user information.");
            }

            const { name } = response.data.message;
            const username = name || "Unknown";

            return username;
        } catch (error) {
            console.error("Error fetching user information for comment:", error.message);
            return "Unknown";
        }
    }

    const showComments = async (comments) => {
        try {
            const updatedComments = await Promise.all(comments.map(async (comment) => {
                if (comment && comment.user) {
                    const username = await getUserInfoForComment(comment.user);
                    return {
                        ...comment,
                        username: username
                    };
                }
                return comment;
            }));

            console.log('Updated comments:', updatedComments);

            setCommentsForPost(updatedComments);
            setShowCommentsModal(true);
        } catch (error) {
            console.error('Error in showComments:', error);
        }
    };

    const closeCommentsModal = () => {
        setShowCommentsModal(false);
        setCommentsForPost([]);
    };
    /*
    const handler = async (postId, link) => {

        try {
            // Make a request to the server to save the joined user
            const response = await axios.post(
                `${BASE_URL}/joined-user`,
                { postId: postId, userId: userId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            // console.log('Response from server:', response.data);

            // Open the link in a new tab
            setTimeout(()=>window.open(link, '_blank'))
            

            if (response.data.success) {
                setJoined(prevJoined => prevJoined + 1);
                toast.success("Successfully joined the bet");
            } else if (response.data.message === 'User already joined this post.') {
                console.log("User already joined this post.");
            } else {
                console.error('Server response indicates unsuccessful operation:', response.data);
            }
        } catch (error) {
            window.open(link, '_blank');

            if (error.response) {
                console.error('Error response from server:', error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up request:', error.message);
            }
        }
    };*/
    const handler = async (postId, link) => {
        try {
            // Make a request to the server to save the joined user
            const response = await axios.post(
                `${BASE_URL}/joined-user`,
                { postId: postId, userId: userId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            // Handle the response
            if (response.data.success) {
                setJoined(prevJoined => prevJoined + 1);
                toast.success("Successfully joined the bet");

                // Open the link in a new tab after a short delay (for async handling)
                setTimeout(() => {
                    const newWindow = window.open(link, '_blank');
                    if (!newWindow) {
                        console.error('Popup blocked. Directing to the link:', link);
                        window.location.href = link; // Direct to the link if popup is blocked
                    }
                }, 100);
            } else if (response.data.message === 'User already joined this post.') {
                console.log("User already joined this post.");
            } else {
                console.error('Server response indicates unsuccessful operation:', response.data);
            }
        } catch (error) {
            console.error('Error during operation:', error);
            const newWindow = window.open(link, '_blank');
            if (!newWindow) {
                console.error('Popup blocked. Directing to the link:', link);
                window.location.href = link;
            }
        }
    };
    //userId = "666ef6fcdb7fe07faac0be3d"

    const fetchUserData = async (userId) => {
        // const myProfileId = "666ef6fcdb7fe07faac0be3d";
        // console.log('fetching id created by:' + userId)
        try {
            const response = await axios.post(`${BASE_URL}/get-user`, { userId });

            if (!response.data.success) {
                throw new Error("Failed to get user information.");
            }

            const { name, avatar } = response.data.message;
            const username = name || "Unknown";
            const userAvatar = avatar || "default-avatar.png";
            if (userAvatar !== "default-avatar.png") {
                setAvatarUrl(userAvatar);
            }

            return { username, avatar: userAvatar };
        } catch (error) {
            console.error("Error fetching user information:", error.message);
            return { username: "Unknown", avatar: "default-avatar.png" };
        }
    };



    useEffect(() => {

        const fetchUserDataForPosts = async () => {

            const userDataPromises = posts.map(post => fetchUserData(post.createdBy));
            const userDataResults = await Promise.all(userDataPromises);
            const userDataMap = {};
            userDataResults.forEach((userData, index) => {
                userDataMap[posts[index]._id] = userData;
            });
            setUserData(userDataMap);

        };

        fetchUserDataForPosts();
    }, [posts]);


    
    function scrollToEnd() {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    }


    return (
        <div className='max-w-[100%]'>
            <Header />
            {loading ? (
                <p>Loading...</p>
            ) : (

                posts.map(post => (
                    <div key={post._id} className="p-4 lg:w-[100%] flex flex-col justify-center  lg:mt-[4rem] text-center   md:max-w-[100%] sm:max-w-[100%]">
                        <div className="grid grid-cols-2 place-items-center mb-4 ">
                            {userData && userData[post._id] && userData[post._id].avatar ? (
                                <>
                                <div className="relative z-2">
                                    
                                    <img
                                        src={userData[post._id].avatar}
                                        alt="User Avatar"
                                        className="w-16 h-16 object-cover rounded-full image-with-checkmark"
                                    />
                                    <div className={`absolute bottom-0 right-0 flex justify-center items-center ${userData[post._id].username === "Gus Skinner"? 'bg-yellow-400' : 'bg-blue-500' } rounded-full w-5 h-5`}>
                                        <BsCheckCircle className="w-6 h-6 text-white" />
                                    </div>
                                </div>
                                <div>
                                    <p className="text-gray-500 text-sm mb-[1rem]">{new Date(post.createdAt).toLocaleString()}</p>
                                    <CustomPostButton title="Join Bet" onClick={() => handler(post._id, post.link)} />
                                </div>
                                <p>{userData[post._id].username}</p>
                                </>
                            ) : (
                                <>
                                <div className="relative z-2">
                                    {/* Placeholder or default avatar */}
                                    <img
                                        src={user}
                                        alt="Default Avatar"
                                        className="w-16 h-16 object-cover rounded-full image-with-checkmark"
                                    />
                                    <div className="absolute bottom-0 right-0 flex justify-center items-center bg-blue-500 rounded-full w-5 h-5">
                                        <BsCheckCircle className="w-6 h-6 text-white" />
                                    </div>
                                    
                                </div>
                                <div>
                                <p className="text-gray-500 text-sm mb-[1rem]">{new Date(post.createdAt).toLocaleString()}</p>
                                <CustomPostButton title="Join Bet" onClick={() => handler(post._id, post.link)} />
                            </div>
                                <p>{"Admin"}</p>
                                </>
                            )}

                            {/* 
                            <div className="relative z-2 ">
                                <img    
                                    // src={userData[post._id].avatar}
                                    src={avatarUrl}
                                    // src={userData.imageUrl}
                                    alt="User Avatar"
                                    className="w-16 h-16 object-cover rounded-full image-with-checkmark"
                                />
                                <div className="absolute bottom-0 right-0 flex justify-center items-center bg-blue-500 rounded-full w-5 h-5">
                                    <BsCheckCircle className="w-6 h-6 text-white" />
                                </div>
                            
                            </div>
                            */}

                            
                            {/* {userData && userData[post._id] && userData[post._id.username] ? */}
                                
                            {/* // } */}
                            {/* <p>{console.log(JSON.stringify(post.post))}</p> */}
                        </div>
                        <div className=' whitespace-normal flex justify-evenly items-start text-justify'>
                            <h3 className=" lg:w-[60%] sm:w-[80%] tracking-normal "> {post.post.replace(/\s+/g, ' ').trim()}</h3>
                        </div>

                        <>
                            {post.imageUrl ? (
                                <div className='flex justify-center items-center mt-[1rem]'>
                                    <ImageModal imageUrl={post.imageUrl} />
                                </div>
                            ) : (
                                <></>
                            )}
                        </>
                        {/* whitespace-normal break-words */}
                        <br /><br /><br />
                        <div className="flex items-center justify-center space-x-4 mb-2 sm:px-5 whitespace-nowrap">
                            <button
                                onClick={() => toggleLike(post._id)}
                                className={`text-xs sm:text-sm flex items-center ${likedPosts.includes(post._id) ? 'text-red-500' : 'text-gray-400'}`}
                            >
                                <FaHeart className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2" />
                                <span>{post.likes.length} {post.likes.length === 1 ? 'like' : 'likes'}</span>
                            </button>
                            <span className="text-xs sm:text-sm text-gray-600">{(joined[post._id] || 0) + post.joinedUsers.length} joined</span>
                            <button
                                onClick={() => toggleCommentModal(post._id)}
                                className="text-xs sm:text-sm flex items-center space-x-1 text-gray-600"
                            >
                                <FaComment className="w-4 h-4 sm:w-5 sm:h-5" />
                                <span>{post.comments.length} {post.comments.length === 1 ? 'comment' : 'comments'}</span>
                            </button>
                            <button
                                onClick={() => showComments(post.comments)}
                                className="text-xs sm:text-sm text-gray-600 ml-2 sm:ml-4"
                            >
                                Show Comments
                            </button>
                        </div>


                        <button
                            className="fixed bottom-4 right-4 bg-green-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full"
                            onClick={scrollToEnd}
                        >
                            V
                        </button>

                        {showCommentModal && commentPostId === post._id && (
                            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                <div className="bg-white p-4 rounded-xl shadow-md max-w-md">
                                    <h2 className="text-lg font-bold mb-2">Write a Comment</h2>
                                    <textarea
                                        value={newComment}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        placeholder="Write your comment here..."
                                        className="w-full p-2 border border-gray-300 rounded mb-2"
                                    ></textarea>
                                    <button onClick={() => handleSaveComment(post._id)} className="bg-green-500 text-white px-4 py-2 rounded">
                                        Save Comment
                                    </button>
                                    <button onClick={() => HandleClose()} className="bg-red-500 text-white px-4 py-2 ml-3 rounded mt-4">
                                        Close
                                    </button>
                                </div>
                            </div>
                        )}

                        {showCommentsModal && (
                            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                <div className="bg-white p-6 rounded-xl shadow-md w-[40%] h-3/4 overflow-y-auto">
                                    <h2 className="text-lg font-bold mb-4">Comments</h2>
                                    {commentsForPost.length > 0 ? (
                                        <ul>
                                            {commentsForPost.map((comment, index) => (
                                                <li key={index} className="mb-4 border p-4 rounded-md">
                                                    {comment.username && (
                                                        <p className="font-semibold mb-1">{comment.username}</p>
                                                    )}
                                                    <p>{comment.comment}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>No comments yet.</p>
                                    )}
                                    <button onClick={closeCommentsModal} className="bg-red-500 text-white px-4 py-2 rounded mt-4">
                                        Close
                                    </button>
                                </div>
                            </div>
                        )}
                        <br />
                        <hr className='w-[60%] bg-gray-600 m-auto h-[2px]' />

                        {showScrollButton && (
                            <button
                                className="fixed bottom-4 right-4 bg-green-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full"
                                onClick={scrollToTop}
                            >
                                ^
                            </button>
                        )}

                    </div>
                ))
            )}
            <Footer />
        </div>
    );
};

export default Post;
