import EditPostCom from '../components/EditPostCom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const EditPost = () => {

  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  

  useEffect(() => {
    if (!token || !role) {
      navigate('/login'); 
    }
  }, [token, navigate]);

 
  return (
    <div className='w-[100%]'>
        <EditPostCom />
    </div>
  );
};

export default EditPost;
