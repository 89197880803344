import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const Header = ({ role }) => {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();
  const [isAdminUser, setisAdminUser] = useState(false);

  const isAdmin = () => {
    const role = localStorage.getItem("role");
    return role === "admin";
  };

  useEffect(() => {
    setisAdminUser(isAdmin());
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const adminLogoutHandler = () => {
    localStorage.removeItem('role');
    navigate("/login");
  }

  const handleNav = () => {
    setNav(!nav);
  };

  // Array containing navigation items
  let navItems = [
    { id: 1, text: 'Home', link: '/newsfeed' },
    { id: 8, text: 'Profile', link: '/profile' },
  ];

  // Include admin links if user is admin
  if (isAdminUser && role === "admin") {
    navItems = [
      { id: 6, text: 'Add Post', link: '/post-page' },
      { id: 7, text: 'Edit/Del Post', link: '/editPost' },
      { id: 8, text: 'Profile', link: '/profile' },
      { id: 9, text: 'interacted users', link: '/interacted-user' }
    ];
  }

  return (
    <div className='bg-white flex justify-between items-center h-24 max-w-[100%] mx-auto px-4 text-black z-50 relative'>
      <h1 className='w-full text-3xl font-bold text-black'> {role === "admin" ? "Rise." : "Rise."}</h1>

      <ul className='hidden md:flex'>
        {navItems.map(item => (
          <li
            key={item.id}
            className='p-4 hover:bg-[#00df9a] rounded-xl mx-2 my-2 max-w-[10rem] cursor-pointer duration-300 hover:text-white whitespace-nowrap z-50'
            onClick={() => navigate(item.link)}
          >
            {item.text}
          </li>
        ))}


        <li
          className='p-4 hover:bg-[#00df9a] rounded-xl mx-2 my-2 cursor-pointer duration-300 hover:text-white z-50'
          onClick={role === "admin" ? adminLogoutHandler : logoutHandler}
        >
          Logout
        </li>
      </ul>

      <div className='block md:hidden'>
        <div onClick={handleNav}>
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
      </div>

      <ul
        className={
          nav
            ? 'fixed md:hidden left-0 top-0 w-screen h-full border-r border-r-gray-900 bg-white ease-in-out duration-500 z-32'
            : 'hidden md:hidden' // Hide the mobile sidebar on larger screens
        }
      >
        {/* Mobile Logo */}
        <h1 className='w-full text-3xl font-bold text-black m-4'> {role === "admin" ? "Rise." : "Rise."}</h1>

        {/* Mobile Navigation Items */}
        {navItems.map(item => (
          <li
            key={item.id}
            className='p-4 border-b rounded-xl hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer border-gray-600 z-3242342323'
            onClick={() => navigate(item.link)}
          >
            {item.text}
          </li>
        ))}

        <li
          className='p-4 border-b rounded-xl hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer border-gray-600'
          onClick={role === "admin" ? adminLogoutHandler : logoutHandler}
        >
          Logout
        </li>

        {/* Close button within the mobile menu */}
        <li className="absolute top-0 right-0 m-4 md:hidden">
          <div onClick={handleNav}>
            <AiOutlineClose size={20} />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Header;
