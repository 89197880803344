import React from 'react';

const Test = ({ postData }) => {
  return (
    <div>
      <h2>{postData.title}</h2>
      <p>{postData.content}</p>
      {/* Other content */}
    </div>
  );
};

export default Test;
