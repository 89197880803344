import React, { useEffect } from 'react';
import { Post } from '../components/Post';
import { Login } from './Login';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const NewsFeed = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) {
      navigate('/login'); 
    }
  }, [token, navigate]);

  return (
    <div className='w-[100%]'>
      {token ? <Post /> : <Login />}
    </div>
    
  );
};

export default NewsFeed;
