import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Test from '../components/Test';

const NewsFeed = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('http://localhost:8000/api/v1/get-posts');
        if (response.data.success) {
          setPosts(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div className='w-[100%]'>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <pre>{JSON.stringify(posts, null, 2)}</pre> Display JSON data using pre tag
          <Test postData={posts}/>
        </>
      )}
    </div>

  );
};

export default NewsFeed;
