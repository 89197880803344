import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Login } from "../pages/Login";
import Header from "../components/Header";
import { BASE_URL } from '../components/BaseUrl';

export const GetInteractedUser = () => {
    const [interactedUsers, setInteractedUsers] = useState([]);
    const role = localStorage.getItem('role');
    const userId = localStorage.getItem('userId');
    console.log("role is : "+ role);
    const navigate = useNavigate();

    useEffect(() => {
        if (role !== "admin") {
            navigate('/login');
        }
    }, [role, navigate]);


    useEffect(() => {
        const fetchInteractedUsers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/posts/interacted-users`);
                if (response.data.success) {
                    setInteractedUsers(response.data.allInteractions);
                } else {
                    console.error('Failed to fetch interacted users:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching interacted users:', error);
            }
        };

        fetchInteractedUsers();
    }, []);

    const formatActions = (user) => {
        const actions = [];
        if (user.liked) actions.push('liked');
        if (user.commented) actions.push('commented');
        if (user.joined) actions.push('joined');
        return actions.join(', ');
    };

    return (
        <>
            {role === "admin" ? (
                <>
                <Header role={role}/>
                
                <div className="container mx-auto px-4 mt-9">
                    <h2 className="text-2xl font-semibold mb-4">Interacted Users</h2>
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full border-collapse">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="px-4 py-2">Post ID</th>
                                    <th className="px-4 py-2">Total People</th>
                                    <th className="px-4 py-2">Liked</th>
                                    <th className="px-4 py-2">Commented</th>
                                    <th className="px-4 py-2">Link Joined</th>
                                    <th className="px-4 py-2">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {interactedUsers.map((interaction) => (
                                    <tr key={interaction.postId}>
                                        <td className="border px-4 py-2">{interaction.postId}</td>
                                        <td className="border px-4 py-2">{interaction.groupedUsers.length}</td>
                                        <td className="border px-4 py-2">{interaction.groupedUsers.filter((user) => user.liked).length}</td>
                                        <td className="border px-4 py-2">{interaction.groupedUsers.filter((user) => user.commented).length}</td>
                                        <td className="border px-4 py-2">{interaction.groupedUsers.filter((user) => user.joined).length}</td>
                                        <td className="border px-4 py-2">
                                            <ul className="list-disc pl-4">
                                                {interaction.groupedUsers.map((user) => (
                                                    <li key={user.userId}>
                                                        {user.email} ({formatActions(user) || 'No activity'})
                                                    </li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                </>
                ) : (<Login />)
            }
        </>
    );

};
