import React, { useState } from 'react';
import { CustomButton } from '../components/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import "../App.css";
import axios from 'axios';
import { BASE_URL } from '../components/BaseUrl';

export const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const url = process.env.REACT_APP_BASEURL;


    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log('from env: ' + url);
        try {
            const response = await axios.post(`${BASE_URL}/login`, formData);
            console.log("response is:", response);
            if (response.data.success) {
                const token = response.data.user.accessToken;
                const userId = response.data.user.id;
                const role = response.data.user.role;
                localStorage.setItem('token', token);
                localStorage.setItem('userId', userId);
                localStorage.setItem('role', role);
                if (role === "user") {
                    navigate('/newsfeed');
                } else if (role === "admin") {
                    navigate("/post-page")
                }
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error('Login error:', error);
            switch (error.response.status) {
                case 400:
                    setErrorMessage("Email and password are required");
                    break;
                case 401:
                    setErrorMessage("Invalid email or password");
                    break;
                case 404:
                    setErrorMessage("User not found");
                    break;
                default:
                    setErrorMessage("An error occurred during login. Please try again.");
                    break;
            }
        }
    };
    

    return (
        <div className='h-screen overflow-hidden'>
            {/* <div className='w-[408px] h-[408px] rounded-full bg-gradient-to-bl from-blue-600 via-transparent to-orange-500 filter blur-[74.5px] absolute left-0 top-0'></div> */}

            {/* circle to the center */}
            <div className='absolute w-[380px] h-[380px] rounded-full blur-46'></div>
            <div className='flex justify-center items-center h-full relative'>
                <form onSubmit={handleSubmit} className='bg-white rounded-lg lg:w-96 h-100 px-8 pt-6 pb-8 mb-4 sm:w-80 animate-slide-in shadow-md z-10'>
                    <h1 className='text-center mb-4 text-2xl font-bold '>Login</h1>
                    <h3 className='text-center mb-6'>Sign in to continue</h3>
                    <div className='mb-4'>
                        <label htmlFor="Email" className='block text-gray-700 text-sm font-bold mb-2'>Email</label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Enter your Email Address"
                            value={formData.email}
                            onChange={handleChange}
                            required={true}
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        />
                    </div>
                    <div className='mb-6'>
                        <label htmlFor="Password" className='block text-gray-700 text-sm font-bold mb-2'>Password</label>
                        <input
                            type="password"
                            name="password"
                            placeholder="Enter your Password"
                            value={formData.password}
                            onChange={handleChange}
                            required={true}
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        />
                    </div>
                    <CustomButton title="Login" className="mb-2" />
                    {errorMessage && <p className="text-red-500 text-center mt-2">{errorMessage}</p>}
                    <h3 className='text-center mt-4'>
                        <Link to="/register" className="hover:underline ">Don't have an account? Register</Link>
                    </h3>
                </form>
                {/* <div className='w-[300px] h-[300px] bg-gradient-to-bl from-blue-600 to-orange-500 filter blur-[74.5px] absolute right-0 bottom-0'></div> */}
            </div>
        </div>
    );

};
