// require("dotenv").config();
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { PostPage } from "./pages/PostPage";
import { UserProfile } from "./pages/UserProfile";
import  DataDisplay  from "./pages/DataDisplay";
import NewsFeed from "./pages/NewsFeed";
import EditPost from "./pages/EditPost";
import { GetInteractedUser } from "./pages/GetInteractedUser";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
    
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/post-page" element={<PostPage />} />
          <Route path="/newsfeed" element={<NewsFeed />} />
          <Route path="/edit-post" element={<EditPost />} />
          <Route path="/showPosts" element={<DataDisplay />} />
          <Route path="/editPost" element={<EditPost />}/>
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/interacted-user" element={<GetInteractedUser />} />
        </Routes>
        <ToastContainer />
      </div>
    </Router>
    </>
  );
}

export default App;
