import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomButton } from '../components/CustomButton';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../components/BaseUrl';


export const Register = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        // dateOfBirth: '',
        image: '',
    });
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            avatar: file,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Create FormData object
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('password', formData.password);
        // formDataToSend.append('dateOfBirth', formData.dateOfBirth);
        formDataToSend.append('image', formData.avatar); // Append avatar file to FormData

        // Send POST request with form data
        axios.post(`${BASE_URL}/register`, formDataToSend)
            .then((response) => {
                console.log('Success:', response.data);
                toast.success('User Registered Successfully');
                navigate("/login");
            })
            .catch((error) => {
                console.error('There was an error!', error);
                switch (error.response.status) {
                    case 400:
                        setErrorMessage("All fields are required");
                        break;
                    case 409:
                        setErrorMessage("User already exists with the same email");
                        break;
                    default:
                        setErrorMessage("An error occurred during registration. Please try again.");
                        break;
                }
            });
    };

    return (
        <div className='flex justify-center items-center h-screen flex-wrap overflow-auto relative'>
            {/* <div className='w-[408px] h-[408px] rounded-full bg-gradient-to-bl from-blue-600 via-transparent to-orange-500 filter blur-[74.5px]'></div> */}

            <form onSubmit={handleSubmit} className='bg-white shadow-md rounded-lg lg:w-96 h-auto px-8 pt-6 pb-8 mb-4 sm:w-80 animate-slide-in' enctype="multipart/form-data">
                <h1 className='text-center text-2xl font-bold'>Create New Account</h1><br />
                {formData.avatar && (
                    <img src={URL.createObjectURL(formData.avatar)} alt="Avatar Preview" className="w-20 h-20 mx-auto mb-2 rounded-full" />
                )}
                <label htmlFor="avatar" className='block text-gray-700 text-sm font-bold'>Avatar</label>
                <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleFileChange}
                    className='mb-2'
                />

                <label htmlFor="Name" className='block text-gray-700 text-sm font-bold'>Name</label>
                <input
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    required={true}
                    onChange={handleChange}
                    value={formData.name}
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2'
                />
                <label htmlFor="email" className='block text-gray-700 text-sm font-bold '>Email</label>
                <input
                    type="email"
                    name="email"
                    placeholder="Enter your Email Address"
                    required={true}
                    onChange={handleChange}
                    value={formData.email}
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2'
                />
                <label htmlFor="password" className='block text-gray-700 text-sm font-bold'>Password</label>
                <input
                    type="password"
                    name="password"
                    placeholder="Enter your Password"
                    required={true}
                    onChange={handleChange}
                    value={formData.password}
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2'
                />
                {/* <label htmlFor="dob" className='block text-gray-700 text-sm font-bold'>Date of Birth</label>
                <input
                    type="date"
                    name="dateOfBirth"
                    placeholder="Select date of birth"
                    required={true}
                    onChange={handleChange}
                    value={formData.dateOfBirth}
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4 '
                /> */}
                <CustomButton title="Sign Up" />
                {errorMessage && <p className="text-red-500 text-center mt-2">{errorMessage}</p>}
                <h3 className='text-center'>
                    <Link to={"/login"}>Already Have An Account? Login</Link>
                </h3>
            </form>
            {/* <div className='w-[300px] h-[300px] bg-gradient-to-bl from-blue-600 to-orange-500 filter blur-[74.5px]'></div> */}
        </div>
    );
};
