import React, { useState, useEffect } from 'react';
import { CustomPostButton } from '../components/CustomPostButton';
import axios from 'axios';
import Header from './Header';
import { BsCheckCircle } from 'react-icons/bs';
import { BASE_URL } from './BaseUrl';
import icon from '../assets/icon.jpg';
import user from "../assets/user.png";

const EditPostCom = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editedPost, setEditedPost] = useState({ post: '', link: '', image: '' });
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [adminName, setAdminName] = useState("");
    const [imageFile, setImageFile] = useState(null);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [userData,setUserData] = useState([]);
    

    // delete code
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [postToDelete, setPostToDelete] = useState(null);
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    const userId = localStorage.getItem('userId');



    // const BASE_URL = "http://localhost:8000/api/v1";

    const handleDeleteClick = (post) => {
        setPostToDelete(post);
        setShowDeleteModal(true);
    };

    const handleCancelDelete = () => {
        setPostToDelete(null);
        setShowDeleteModal(false);
    };

    const handleConfirmDelete = async () => {
        if (postToDelete) {
            try {
                const response = await axios.delete(`${BASE_URL}/posts/${postToDelete._id}`);
                console.log('Deleted post:', response.data);

                const updatedResponse = await axios.get(`${BASE_URL}/get-posts`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (updatedResponse.data.success) {
                    setPosts(updatedResponse.data.message);
                }

                setShowDeleteModal(false);
            } catch (error) {
                console.error('Error deleting post:', error);
            }
        }
    };

    function scrollToEnd() {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    }

    
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 100) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/get-posts`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.data.success) {
                    console.log("api response is : " + JSON.stringify(response.data.message));
                    const sortedPosts = response.data.message.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setPosts(sortedPosts);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };
    
        fetchPosts();
    }, [token]);
    

    const handleEditClick = (postId) => {
        console.log("Clicked post ID:", postId);
        const selectedPost = posts.find(post => post._id === postId);
        setShowModal(true);
        setEditedPost({ ...selectedPost });
    };



    const handleCancelClick = () => {
        setShowModal(false);
        setEditedPost({ post: '', link: '' });
    };

    const handleUpdateClick = async () => {
        try {
            const formData = new FormData();
            formData.append('post', editedPost.post);
            formData.append('link', editedPost.link);
            formData.append('image', imageFile); 

            const response = await axios.put(`${BASE_URL}/posts/${editedPost._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Updated post data:', response.data);

            const updatedResponse = await axios.get(`${BASE_URL}/get-posts`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (updatedResponse.data.success) {
                setPosts(updatedResponse.data.message);
            }

            setShowModal(false);
        } catch (error) {
            console.error('Error updating post:', error);
        }
    };


    const handleImageUpload = (files) => {
        if (files.length > 0) {
            setImageFile(files[0]);
        }
    };


        const fetchUserData = async (userId) => {
            // const myUserId = "666ef6fcdb7fe07faac0be3d";
            // const myUserId = userId
            try {
                const response = await axios.post(`${BASE_URL}/get-user`, { userId });

                if (!response.data.success) {
                    throw new Error("Failed to get user information.");
                }

                const { name, avatar } = response.data.message;
                const username = name || "Unknown";
                const userAvatar = avatar || "default-avatar.png";
                if (userAvatar !== "default-avatar.png") {
                    setAvatarUrl(userAvatar);
                }
                if (username !== "Unknown") {
                    setAdminName(username);
                }

                return { username, avatar: userAvatar };
            } catch (error) {
                console.error("Error fetching user information:", error.message);
                return { username: "Unknown", avatar: "default-avatar.png" };
            }
        };


    useEffect(() => {

        const fetchUserDataForPosts = async () => {

            const userDataPromises = posts.map(post => fetchUserData(post.createdBy));
            const userDataResults = await Promise.all(userDataPromises);
            const userDataMap = {};
            userDataResults.forEach((userData, index) => {
                userDataMap[posts[index]._id] = userData;
            });
            setUserData(userDataMap);
            
        };

        fetchUserDataForPosts();
    }, [posts]);

   
    return (
        <div>
            {!loading && token && role === "admin" ? (
                <>
                    <Header role={role} />
                    {posts.map(post => (
                        <div key={post._id} className="p-4 w-[80%] mx-auto mt-[4rem] text-center z-10">
                            <div className="grid grid-cols-2 place-items-center mb-4">
                            {userData && userData[post._id] && userData[post._id].avatar ? (
                                <>
                                <div className="relative z-2">
                                    
                                    <img
                                        src={userData[post._id].avatar}
                                        alt="User Avatar"
                                        className="w-16 h-16 object-cover rounded-full image-with-checkmark"
                                    />
                                    <div className={`absolute bottom-0 right-0 flex justify-center items-center ${userData[post._id].username === "Gus Skinner"? 'bg-yellow-400' : 'bg-blue-500' } rounded-full w-5 h-5`}>
                                        <BsCheckCircle className="w-6 h-6 text-white" />
                                    </div>
                                </div>
                                <div>
                                    <p className="text-gray-500 text-sm mb-[1rem]">{new Date(post.createdAt).toLocaleString()}</p>
                                    <CustomPostButton title="Edit" onClick={() => handleEditClick(post._id)} />
                                    <button className="bg-red-500 w-[6rem] h-[2.5rem] text-white m-auto flex justify-center items-center border-none rounded-lg mt-2" onClick={() => handleDeleteClick(post)}>Delete</button>
                                </div>
                                <p>{userData[post._id].username}</p>
                                </>
                            ) : (
                                <>
                                <div className="relative z-2">
                                    {/* Placeholder or default avatar */}
                                    <img
                                        src={user}
                                        alt="Default Avatar"
                                        className="w-16 h-16 object-cover rounded-full image-with-checkmark"
                                    />
                                    <div className="absolute bottom-0 right-0 flex justify-center items-center bg-blue-500 rounded-full w-5 h-5">
                                        <BsCheckCircle className="w-6 h-6 text-white" />
                                    </div>
                                    
                                </div>
                                <div>
                                    <p className="text-gray-500 text-sm mb-[1rem]">{new Date(post.createdAt).toLocaleString()}</p>
                                    <CustomPostButton title="Edit" onClick={() => handleEditClick(post._id)} />
                                    <button className="bg-red-500 w-[6rem] h-[2.5rem] text-white m-auto flex justify-center items-center border-none rounded-lg mt-2" onClick={() => handleDeleteClick(post)}>Delete</button>
                                </div>
                                <p>{"Admin"}</p>
                                </>
                            )}


                                {/* 
                                <div className="relative">
                                    <img
                                        src={avatarUrl}
                                        alt="User Avatar"
                                        className="w-16 h-16 object-cover rounded-full"
                                    />
                                    <div className="absolute bottom-0 right-0 flex justify-center items-center bg-blue-500 rounded-full w-5 h-5">
                                        <BsCheckCircle className="w-6 h-6 text-white" />
                                    </div>

                                </div>
                                <div>
                                    <p className="text-gray-500 text-sm mb-[1rem]">{new Date(post.createdAt).toLocaleString()}</p>
                                    <CustomPostButton title="Edit" onClick={() => handleEditClick(post._id)} />
                                    <button className="bg-red-500 w-[6rem] h-[2.5rem] text-white m-auto flex justify-center items-center border-none rounded-lg mt-2" onClick={() => handleDeleteClick(post)}>Delete</button>
                                </div>
                                <p>{adminName}</p>
                                */}
                            </div>
                            <div className='flex justify-evenly items-start pl-[1rem] text-justify'>
                                <h3 className="whitespace-normal break-words lg:w-[60%] sm:w-[80%]">{post.post}</h3>
                            </div>
                            <>
                                {post.imageUrl && (
                                    <div className='flex justify-center items-center mt-[1rem]'>
                                        <img src={post.imageUrl} alt="post image" width={200} height={250} />
                                    </div>
                                )}
                            </>
                            <br /><br /><br />
                            <hr className='w-[60%] bg-gray-600 m-auto h-[2px]' />
                            <br /><br />
                            <button
                            className="fixed bottom-4 right-4 bg-green-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full"
                            onClick={scrollToEnd}
                        >
                            V
                        </button>

                            {/* Edit Modal */}
                            {showModal && (
                                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                    <div className="bg-white p-4 rounded shadow-md max-w-md mx-auto">
                                        <h2 className="text-lg font-bold mb-2">Edit Post</h2>
                                        <label htmlFor="post">Post:</label>
                                        <input
                                            type="text"
                                            id="post"
                                            value={editedPost.post}
                                            onChange={(e) => setEditedPost({ ...editedPost, post: e.target.value })}
                                            className="w-full p-2 border border-gray-300 rounded mb-2"
                                        />
                                        <label htmlFor="link">Link:</label>
                                        <input
                                            type="text"
                                            id="link"
                                            value={editedPost.link}
                                            onChange={(e) => setEditedPost({ ...editedPost, link: e.target.value })}
                                            className="w-full p-2 border border-gray-300 rounded mb-2"
                                        />
                                        {/* <img src={post.imageUrl} alt="" /> */}
                                        {editedPost.image && (
                                            <div className="mt-4">
                                                <img src={editedPost.image} alt="Existing Image" className="w-full" />
                                            </div>
                                        )}

                                        <label htmlFor="image">Add updated  :</label>
                                        <input
                                            type="file"
                                            id="image"
                                            accept="image/*"
                                            name='image'
                                            onChange={(e) => handleImageUpload(e.target.files)}
                                            className="w-full p-2 border border-gray-300 rounded mb-2"
                                        />
                                        <div className="flex justify-end">
                                            <button onClick={handleCancelClick} className="mr-2">Cancel</button>
                                            <button onClick={handleUpdateClick} className="bg-green-500 text-white px-4 py-2 rounded">Update</button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Delete Modal */}
                            {showDeleteModal && (
                                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                    <div className="bg-white p-4 rounded shadow-md max-w-md">
                                        <h2 className="text-lg font-bold mb-2">Delete Post</h2>
                                        <p>Are you sure you want to delete this post?</p>
                                        <div className="flex justify-end">
                                            <button onClick={handleCancelDelete} className="mr-2">Cancel</button>
                                            <button onClick={handleConfirmDelete} className="bg-red-500 text-white px-4 py-2 rounded">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {showScrollButton && (
                                <button
                                    className="fixed bottom-4 right-4 bg-green-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full"
                                    onClick={scrollToTop}
                                >
                                    ^
                                </button>
                            )}

                        </div>
                    ))}
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};


export default EditPostCom;