import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import { BASE_URL } from '../components/BaseUrl';

export const UserProfile = () => {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        // dateOfBirth: '',
        role: '',
        avatar: '',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [avatarFile, setAvatarFile] = useState(null);
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/get-user`, { userId }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const userData = response.data.message;
            // const dateOfBirth = new Date(userData.dateOfBirth).toISOString().split('T')[0];
            setUserData({ ...userData });
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error.message);
        }
    };

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        setAvatarFile(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log('user id is: '+ userId);
            const formData = new FormData();
            formData.append('userId',userId);
            formData.append('image', avatarFile);

            const response = await axios.put(`${BASE_URL}/update-pic`, formData);

            const updatedUserData = { ...userData, avatar: response.data.avatarUrl };
            setUserData(updatedUserData);
            fetchUserData();
        } catch (error) {
            console.error('Error uploading avatar:', error.message);
        }
    };

    return (
        <>
            <Header role={role} />
            <div className="container mx-auto p-4 w-[100%]">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        <div className="mb-4">
                            <img
                                src={userData.avatar}
                                alt="Avatar"
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                }}
                            />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                    Name
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="name"
                                    type="text"
                                    placeholder="Name"
                                    value={userData.name}
                                    readOnly
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="email"
                                    placeholder="Email"
                                    value={userData.email}
                                    readOnly
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
                                    Role
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="role"
                                    type="text"
                                    placeholder="Role"
                                    value={userData.role}
                                    readOnly
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="avatar">
                                    Choose New Profile Pic
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="avatar"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleAvatarChange}
                                />
                            </div>
                            <button type="submit" className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Save</button>
                        </form>
                    </>
                )}
            </div>
        </>
    );
};
