import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <footer className="bg-white rounded-lg shadow-md white:bg-white mt-8">
            <hr className="border-t border-gray-200 my-4 " />
            <div className="w-full mx-auto max-w-screen-xl p-4 flex justify-center">
                <span className="text-sm text-gray-500 text-center dark:text-gray-400">
                    © 2024 Rise. <Link to="/" className="hover:underline">All Rights Reserved.</Link>
                </span>     
            </div>
        </footer>
    );
}
