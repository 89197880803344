import React, { useState, useEffect } from 'react';
import { CustomButton } from '../components/CustomButton';
import { CustomPostButton } from '../components/CustomPostButton';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Header from "../components/Header";
import {Login} from "../pages/Login";
// import { BASE_URL } from '../components/BaseUrl';

const BASE_URL = "https://rise-app-server-side.onrender.com/api/v1";

export const PostPage = () => {
    const [formData, setFormData] = useState({
        post: '',
        link: '',
        image: '', 
    });

    const [message, setMessage] = useState('');
    const role = localStorage.getItem('role');
    const userId = localStorage.getItem('userId');
    const navigate = useNavigate();

    function savePost() {
        const postData = new FormData(); // Create FormData object
        postData.append('post', formData.post);
        postData.append('link', formData.link);
        postData.append('userId', userId);
        postData.append('image', formData.image); // Append image to FormData

        axios.post(`${BASE_URL}/post-page`, postData)
            .then(response => {
                console.log('Post saved:', response.data);
                setMessage('Post uploaded successfully!');
            })
            .catch(error => {
                console.error('Error saving post:', error);
                setMessage('Error uploading post. Please try again.');
            });
    }

    function submitHandler(e) {
        e.preventDefault();
        savePost();
    }

    function changeHandler(e) {
        if (e.target.name === 'image') {
            setFormData({ ...formData, image: e.target.files[0] }); // Update image state
        } else {
            const { name, value } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    }

    function logoutHandler() {
        localStorage.removeItem('role');
        navigate("/login");
    }

    useEffect(() => {
        if (!role) {
            navigate('/login');
        }
    }, [role, navigate]);


    return (
        <>
            {role === "admin" ? (
                <>
                    <Header role={role} />

                    <div className='flex justify-center items-center h-screen bg-white'>
                        <form onSubmit={submitHandler} className='bg-white shadow-md rounded-lg lg:w-full max-w-lg px-8 pt-6 pb-8 animate-slide-in sm:w-[90%]'>
                            <h1 className='text-center mb-4 text-2xl font-bold'>Create a Post</h1>
                            <div className='mb-4'>
                                <textarea
                                    placeholder='Start Typing...'
                                    required={true}
                                    onChange={changeHandler}
                                    value={formData.post}
                                    name='post'
                                    className='shadow appearance-none border rounded w-full h-40 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none overflow-y-auto'
                                />
                            </div>
                            <div className='mb-4'>
                                <label htmlFor="postLink" className='block text-gray-700 text-sm font-bold mb-2'>Insert Link</label>
                                <input
                                    type="text"
                                    placeholder='Enter your link here'
                                    onChange={changeHandler}
                                    value={formData.link}
                                    name='link'
                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="image" className="block text-gray-700 text-sm font-bold mb-2">Upload Image</label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={changeHandler}
                                    name="image"
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            <div className='flex justify-center'>
                                <CustomButton title='Post' />
                            </div>
                            {message && <p className="text-center text-red-500 mt-4">{message}</p>}
                        </form>

                    </div>
                </>
            ) : (
                <Login />
            )}
        </>
    );
};

export default PostPage;
